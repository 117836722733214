import DashboardCard from "../../../../components/dashboardCard/index";
import ProjectList from "../../../../components/project-list/index";
import DialogDashboard from "../../../../components/dialog-dashboard/index";
import employerServices from "../../../../core/services/modules/employerServices";
import headerSection from "../../../../components/header-section/index";
import freelancerServices from "../../../../core/services/modules/freelancerServices";
import $changeDate from "@/shared/mixins/changeDate";
export default {
  name: "project-detail",
  components: {
    DashboardCard,
    ProjectList,
    DialogDashboard,
    headerSection
  },
  props: [],
  mixins: [$changeDate],
  data() {
    return {
      pageCount: 5,
      page: 1,
      showSelect: true,
      attachments: [],
      totalData: null,
      status: "pending",
      projectDetails: {},
      proposalForm: {},
      proposalOtherInfo: {},
      // nameRules: [
      //   v => !!v || "Name is required",
      //   v => (v && v.length <= 50) || "Name must be less than 10 characters"
      // ],
      projectListItems: [
        {
          id: 1,
          name: "name",
          title: "ssssss",
          amount: "در انتظار تایید کارفرما",
          time: "29/2/1400",
          expirationStatus: "منقضی شده",
          status: "statusssssss"
        }
      ]
    };
  },
  computed: {
    totalPage() {
      return 3;
    }
  },
  mounted() {
    this.showDetailProject();
    this.getProposalsById();
  },
  methods: {
    showDetailProject() {
      const id = this.$route.params.id;
      employerServices.projectShowByIdForFreelancer(id).then(res => {
        this.projectDetails = res.data.data;
      });
    },
    getProposalsById() {
      freelancerServices
        .getPendingProposalById(this.$route.query.proposalId)
        .then(res => {
          this.proposalForm = res.data.data;
          this.proposalOtherInfo = res.data.data;
          this.attachments = res.data.data.attachments;
        });
    },
    changePage(currentPage) {
      const options = {
        status: this.status,
        page: currentPage,
        perPage: 5
      };
      console.log(options);
    },
    goToConversion() {
      this.$router.push({
        path: `conversationWithEmployer`,
        query: { proposalId: this.$route.query.proposalId }
      });
    }
  }
};
