import dayjs from "dayjs";
import jalaliday from "jalaliday";
dayjs.extend(jalaliday).locale("fa");
dayjs.calendar("jalali").locale("fa");

export default {
  methods: {
    $changeDate(date) {
      console.log(date);
      return (date = "eeee");
    }
  }
};
